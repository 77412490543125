<template>
  <div id="voting-rights">
    <v-app-bar
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-toolbar-title>
        {{ PageTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-card-text>
        <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
          <v-row wrap>
            <v-col cols="12" sm="12" md="12" lg="12">
              <h5 align="center" class="text-dark">
                Please download
                <a :href="SampleFilePath" target="_blank" download>
                  Sample Upload File
                </a>
                here
              </h5>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-file-input
                :clearable="false"
                truncate-length="25"
                accept="application/pdf"
                placeholder="Upload pdf"
                v-model="UploadedFile"
                prepend-icon="mdi-file-pdf"
              >
                <template v-slot:append-outer>
                  <v-icon color="red" @click="UploadedFile = {}">
                    mdi-delete
                  </v-icon>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid1"
          @click.prevent="confirmAlert"
          :loading="SubmitFlag"
          elevation="30"
          shaped
          tile
          small
          color="#1db954"
          class="font-size-h6 px-10 mr-3 white--text"
        >
          Save
        </v-btn>
        <v-btn
          @click="closePrompt"
          elevation="30"
          shaped
          tile
          small
          color="#a52a2a"
          class="font-size-h6 px-10 mr-3 white--text"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  props: {
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      ResultFlag: false,

      progessStart: 0,
      uploadPercentage: 0,

      rows: [],
      member: {},

      UploadedFile: {},

      DocumentTypeRules: [(v) => !!v || "Document Type is required"],
      DocumentType: "",
      DocumentTypeOptions: [],
      DocumentTypeOptionsLoading: false,

      PageTitle: "Upload Voting Rights Document",
    };
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var server_url = companyConfig.apiURL;
      console.log("server_url = " + server_url);
      var SampleFilePath = server_url + "assets/sample/SS-Card-format.pdf";
      console.log("SampleFilePath = " + SampleFilePath);
      this.SampleFilePath = SampleFilePath;
    },

    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm called");

      this.ResultFlag = false;

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      var message = "";

      var lom = this.recordData.LomId;
      console.log({ lom });
      var member = this.recordData.LomPresidentMemberId;
      console.log({ member });

      if (validate1) {
        var form = {
          Lom: lom,
          Member: member,
        };

        let upload = new FormData();
        upload.append("UserInterface", 4);
        upload.append("Form", JSON.stringify(form));
        upload.append("uploadFile1", this.UploadedFile);

        console.log("upload=" + JSON.stringify(upload));

        this.SubmitFlag = true;
        this.progessStart = 1;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-document/store";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            thisIns.ResultFlag = flag;

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
            thisIns.progessStart = 0;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill required fields.";
        }
        if (!uploadFile1) {
          message += "File is not selected";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
    this.member = {};
  },
};
</script>
    <style lang="scss">
#voting-rights {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>