<template>
  <div id="jcom-table-preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <v-row v-if="row.LomPresidentMemberId > 0">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img
                  width="100"
                  height="auto"
                  :src="row.LomPresidentImagePath"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>LO Status</label>
                <h5>
                  <v-chip :color="row.LomStatusColor" draggable dark>{{
                    row.LomStatusTxt
                  }}</v-chip>
                </h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>SS Card Status</label>
                <h5>
                  <v-chip
                    :color="getActiveStatusColor(row.LomDocumentStatusTxt)"
                    draggable
                    dark
                    >{{ row.LomDocumentStatusTxt }}</v-chip
                  >
                </h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Zone & LO Name</label>
                <h5>{{ row.ZoneName }} - {{ row.LomName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>President Name</label>
                <h5>
                  {{ row.LomPresidentMembershipId }} -
                  {{ row.LomPresidentName }}
                </h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Profile Update</label>
                <br /><br />
                &nbsp;
                <v-chip
                  :color="`${
                    row.LomPresidentProfileUpdated ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{
                    row.LomPresidentProfileUpdated ? "Completed" : "Pending"
                  }}
                </v-chip>
              </v-col>
            </v-row>
            <v-divider />
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" lg="12" md="12">
                <h3 class="text-dark text-center">LO Voting Calculation</h3>
                <p
                  v-if="row.VotingDescriptionTxt1 != ''"
                  class="font-size-h6 mt-3 text-left"
                  v-html="row.VotingDescriptionTxt1"
                ></p>
              </v-col>
            </v-row>
            <v-divider />
          </v-container>
          <br />
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  components: {
  },
  props: {
    row: {
      required: true,
    },
  },
  data() {
    return {
      valid1: false,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      ResultFlag: false,

      ActiveStatus: 1,

      PageInfo: {},
      PageTitle: "Voting Rights Preview",
      PageDescription: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
  },
  beforeMount() {},
};
</script>
      <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>