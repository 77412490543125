<template>
  <div id="jcom-table-preview">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>

          <v-container v-if="row.LomPresidentMemberId > 0">
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="confirmAlert"
            >
              <v-row>
                <v-col cols="12" sm="12" md="12" class="text-center">
                  <img
                    width="100"
                    height="auto"
                    :src="row.LomPresidentImagePath"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>LO Status</label>
                  <h5>
                    <v-chip :color="row.LomStatusColor" draggable dark>{{
                      row.LomStatusTxt
                    }}</v-chip>
                  </h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>SS Card Status</label>
                  <h5>
                    <v-chip
                      :color="getActiveStatusColor(row.LomDocumentStatusTxt)"
                      draggable
                      dark
                      >{{ row.LomDocumentStatusTxt }}</v-chip
                    >
                  </h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Zone & LO Name</label>
                  <h5>{{ row.ZoneName }} - {{ row.LomName }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>President Name</label>
                  <h5>
                    {{ row.LomPresidentMembershipId }} -
                    {{ row.LomPresidentName }}
                  </h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Mobile Number</label>
                  <h5>{{ row.LomPresidentMobileNo }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Email ID</label>
                  <h5>{{ row.LomPresidentEmailid }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Date of Birth</label>
                  <h5>{{ row.LomPresidentDOB }}</h5>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <label>Profile Update</label>
                  <br /><br />
                  &nbsp;
                  <v-chip
                    :color="`${
                      row.LomPresidentProfileUpdated ? '#0f9d58' : '#f4b400'
                    }`"
                    draggable
                    dark
                    >{{
                      row.LomPresidentProfileUpdated ? "Completed" : "Pending"
                    }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <label>Age Proof Document</label>
                  <br />
                  &nbsp;
                  <v-row>
                    <v-col cols="12" sm="12" md="4" lg="2">
                      <v-chip
                        :color="`${
                          row.MemberDocumentFlag ? '#0f9d58' : '#f4b400'
                        }`"
                        draggable
                        dark
                        >{{ row.MemberDocumentFlag ? "Completed" : "Pending" }}
                      </v-chip>
                    </v-col>
                    <v-col cols="12" sm="12" md="8" lg="10">
                      <viewer rebuild :images="row.MemberDocumentPathArray">
                        <img
                          class="mr-4"
                          width="80"
                          height="80"
                          :src="src"
                          :key="i"
                          v-for="(src, i) in row.MemberDocumentPathArray"
                        />
                      </viewer>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider />
              <v-row wrap v-if="row.LomPayments.length > 0">
                <v-col align="center" cols="12" sm="12" lg="12" md="12">
                  <h3 class="text-dark text-center">LO Voting Calculation</h3>
                  <p
                    v-if="row.VotingDescriptionTxt1 != ''"
                    class="font-size-h6 mt-3 text-left"
                    v-html="row.VotingDescriptionTxt1"
                  ></p>
                  <v-data-table
                    no-data-text="No puchase products found"
                    class="elevation-1"
                    disable-sort
                    :items="row.LomPayments"
                    :headers="lomPaymentHeader"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:[`item.StatusTxt`]="{ item }">
                      <v-chip :color="item.StatusColor" draggable dark>{{
                        item.StatusTxt
                      }}</v-chip>
                    </template>
                    <template v-slot:[`item.EligibleCount`]="{ item }">
                      <v-chip :color="item.StatusColor" draggable dark>{{
                        item.EligibleCount
                      }}</v-chip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h3 class="text-dark text-center">SS Card</h3>
                  <div v-if="row.LomDocumentPath != ''">
                    <h6>
                      <a :href="row.LomDocumentPath" target="_blank">
                        Download SS Card
                      </a>
                    </h6>
                  </div>
                  <div v-else>
                    <v-chip color="#f4b400" draggable dark>Pending </v-chip>
                  </div>
                  <br/>
                  <v-icon
                    icon
                    size="25px"
                    color="primary"
                    @click.prevent="uploadDocumentPrompt = true"
                  >
                    mdi-cloud-upload
                  </v-icon>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col
                  cols="12"
                  lg="12"
                  sm="12"
                  md="12"
                  v-if="row.ReasonHistory != ''"
                >
                  <v-divider />
                  <h3 class="text-center">SS Card Approval History</h3>
                  <p class="font-size-h6 mt-3" v-html="row.ReasonHistory"></p>
                </v-col>
                <v-col cols="12" lg="12" sm="12" md="12">
                  <v-divider />
                  <label>
                    <h3>
                      <span class="text-danger">*</span> SS Card Approval
                      Description
                    </h3>
                  </label>
                  <v-textarea
                    outlined
                    v-model="Reason"
                    :rules="ReasonRules"
                    name="input-7-4"
                    label="Reason"
                    value=""
                  ></v-textarea>
                </v-col>
                <v-col cols="12" lg="12" sm="12" md="12" class="text-center">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(1)"
                    color="success"
                    elevation="30"
                    medium
                    class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                  >
                    Approved
                  </v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(3)"
                    color="error"
                    elevation="30"
                    medium
                    class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                  >
                    Reject
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    color="#F44336"
                    elevation="30"
                    medium
                    class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <h3>LO President details not found.</h3>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-container>
    </v-card>
    <v-dialog v-model="uploadDocumentPrompt" max-width="70%" persistent>
      <voting-rights-upload
        :addRecordPrompt="uploadDocumentPrompt"
        @hideAddRecordPrompt="hideUploadRecordPrompt"
        :recordData="row"
        v-if="uploadDocumentPrompt"
      ></voting-rights-upload>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import VotingRightsUpload from "@/view/pages/erp/reports/VotingRightsUpload.vue";

export default {
  mixins: [common],
  components: {
    Cropper,
    CircleStencil,
    VotingRightsUpload,
  },
  props: {
    row: {
      required: true,
    },
    lomPaymentHeader: {
      required: true,
    },
  },
  data() {
    return {
      valid1: false,
      valid2: true,
      LoadingFlag: false,
      SubmitFlag: false,

      UploadFlag: false,
      ResultFlag: false,

      uploadDocumentPrompt: false,

      Reason: "",
      ReasonRules: [(v) => !!v || "Reason is required"],
      menu1: false,

      ActiveStatus: 1,

      PageInfo: {},
      PageTitle: "Voting Rights Approval",
      PageDescription: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog", this.ResultFlag);
    },
    hideUploadRecordPrompt(flag) {
      console.log("hideUploadRecordPrompt called");
      this.uploadDocumentPrompt = false;
      if (flag) {
        this.ResultFlag = true;
        this.closePrompt();
      }
    },
    confirmAlert(status) {
      console.log({ status });
      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.updateActiveStatus(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
    updateActiveStatus(status) {
      console.log("updateActiveStatus is called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      var Status = status;
      var Reason = this.Reason;
      var tr = this.row;
      console.log({ tr });
      if (validate1) {
        this.LoadingFlag = true;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom-document/status-update";
        var upload = {
          UserInterface: 1,
          Status: Status,
          Reason: Reason,
          Member: tr.LomPresidentMemberId,
          Lom: tr.LomId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, true);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
  },
  beforeMount() {},
};
</script>
      <style lang="scss">
#module-create {
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>